<script setup>
import { onMounted, onBeforeUnmount } from "vue";
import { useStore } from "vuex";
//import Navbar from "@/examples/PageLayout/Navbar.vue";
//import AppFooter from "@/examples/PageLayout/Footer.vue";
import Navbar from "@/dw123/PageLayout/Navbar.vue";
import AppFooter from "@/dw123/PageLayout/Footer.vue";
import setNavPills from "@/assets/js/nav-pills.js";
import Card from "@/dw123/components/Card.vue";

const store = useStore();

onMounted(() => {
  store.state.showSidenav = false;
  store.state.showNavbar = false;
  store.state.showFooter = false;
  setNavPills();
});
onBeforeUnmount(() => {
  store.state.showSidenav = true;
  store.state.showNavbar = true;
  store.state.showFooter = true;
  if (store.state.isPinned === false) {
    const sidenav_show = document.querySelector(".g-sidenav-show");
    sidenav_show.classList.remove("g-sidenav-hidden");
    sidenav_show.classList.add("g-sidenav-pinned");
    store.state.isPinned = true;
  }
});
</script>
<template>
  <navbar />
  <div
    class="page-header position-relative"
    :style="{
      backgroundImage:
        'url(' + require('../assets/img/bg-site-01.jpg') + ')',
      backgroundSize: 'cover',
    }"
  >
    <span class="mask bg-gradient-info opacity-1"></span>
    <div class="container pb-10 pb-lg-9 pt-7 postion-relative">
      <div class="row">
        <div class="mx-auto text-center col-md-8 mt-4">
          <h3 class="text-white">
            <span class="d-none d-sm-block">DayWork123.com is a </span>
            Completely Free
            <br>
            Worldwide Yacht & Maritime Crew Bulletin Board
          </h3>
          <p class="text-white">
            Serving Over 387 Ports of Call in Addition to All the Major Yachting Hubs of the World.
          </p>
        </div>
      </div>
    </div>
  </div>

  <div class="mt-n8 position-relative z-index-2">
    <div class="container">
      <div class="row justify-content-center">
        <div class="mb-4 col-lg-4 mb-lg-0">
          <card
            badge="Workers"
            :actions="[
              { label: 'Register for Jobs', color: 'info', route: 'AddCrew' },
              { label: 'View Jobs Announcements', color: 'info', route: 'javascript:;' }
            ]"
          />
        </div>
        <div class="mb-4 col-lg-4 mb-lg-0">
          <card
            badge="Employers"
            cardBackground="gradient-info"
            headerBackground="transparent"
            :actions="[
              { label: 'View Available Workers', color: 'light', route: 'javascript:;' },
              { label: 'Add a Job Announcement', color: 'light', route: 'javascript:;' }
            ]"
          />
        </div>
      </div>
    </div>
  </div>
  <app-footer />
</template>
