<template>
  <footer class="py-5 footer">
    <div class="container">
      <div class="row">
        <div class="mx-auto mt-1 text-center col-8">
          <p class="mb-0 text-secondary">
            Copyright © {{ new Date().getFullYear() }} DayWork123.com 
            by <a href="https://www.ngyi.com/" target="_blank">Neptune Group Yachting Inc.</a>.
          </p>
        </div>
      </div>
    </div>
  </footer>
</template>
