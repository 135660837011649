<script setup>
import { onMounted, onBeforeUnmount } from "vue";
import { useStore } from "vuex";

import * as Choices from "choices.js";

import Navbar from "@/dw123/PageLayout/Navbar.vue";
import AppFooter from "@/dw123/PageLayout/Footer.vue";
//import ButtonRadioGroup from "@/dw123/components/ButtonRadioGroup.vue";

import setNavPills from "@/assets/js/nav-pills.js";

import ArgonInput from "@/components/ArgonInput.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import ArgonSwitch from "@/components/ArgonSwitch.vue";
import flatPickr from "vue-flatpickr-component";
import Dropzone from "dropzone";

const date = "";
const config = {
  wrap: true, // set wrap to true only when using 'input-group'
  allowInput: true,
  dateFormat: 'Y-m-d',
};

const harborOptions = [
        {
          label: "Beef Island, Tortola, BVI, United Kingdom",
          value: "1",
        },
        {
          label: "Bitter End, Virgin Gorda, BVI, United Kingdom",
          value: "2",
        },
        {
          label: "Caicos Marina, Providenciales, Turks &amp; Caicos, United Kingdom",
          value: "3",
        },
        {
          label: "Charlotte Amalie, St Thomas, USVI, United States (USA)",
          value: "4",
        },
        {
          label: "Christiansted, St Croix, USVI, United States (USA)",
          value: "5",
        },
        {
          label: "Cockburntown, Grand Turk, Turks &amp; Caicos, United Kingdom",
          value: "6",
        }
      ];

const quickPickHarborsOptions = [
    {
      label: 'Fort Lauderdale',
      value: '1', 
    }, 
    {
      label: 'Miami',
      value: '2', 
    }, 
    {
      label: 'Palm Beach',
      value: '3', 
    },
    {
      label: 'Antibes',
      value: '4', 
    },
    {
      label: 'Barcelona',
      value: '5', 
    },
    {
      label: 'Palma de Majorca',
      value: '6', 
    },
    {
      label: 'Antigua',
      value: '7', 
    }, 
    {
      label: 'St. Martin',
      value: '8', 
    }, 
    {
      label: 'St. Thomas USVI',
      value: '9', 
    }, 
    {
      label: 'Annapolis',
      value: '10', 
    }, 
    {
      label: 'Newport',
      value: '11', 
    }, 
    {
      label: 'Nantucket',
      value: '12', 
    }, 
    {
      label: 'Bar Harbour',
      value: '13', 
    }, 
  ];
//let quickPickHarbors = '';


const store = useStore();

onMounted(() => {
  store.state.showSidenav = false;
  store.state.showNavbar = false;
  store.state.showFooter = false;
  setNavPills();

  if (document.getElementById("position-seeking")) {
    let positions = document.getElementById("position-seeking");
    new Choices(positions, { allowHTML: true });
  }
  if (document.getElementById("browse-port")) {
    let ports = document.getElementById("browse-port");
    new Choices(ports, { allowHTML: true });
  }

  Dropzone.autoDiscover = false;
  var drop = document.getElementById("dropzone");
  new Dropzone(drop, {
    url: "/file/post",
    addRemoveLinks: true,
  });

});
onBeforeUnmount(() => {
  store.state.showSidenav = true;
  store.state.showNavbar = true;
  store.state.showFooter = true;
  if (store.state.isPinned === false) {
    const sidenav_show = document.querySelector(".g-sidenav-show");
    sidenav_show.classList.remove("g-sidenav-hidden");
    sidenav_show.classList.add("g-sidenav-pinned");
    store.state.isPinned = true;
  }
});
</script>
<template>
  <navbar />
  <div
    class="page-header position-relative"
    :style="{
      backgroundImage:
        'url(' + require('../assets/img/bg-site-01.jpg') + ')',
      backgroundSize: 'cover',
    }"
  >
    <span class="mask bg-gradient-info opacity-1"></span>
    <div class="container pb-10 pb-lg-9 pt-7 postion-relative z-index-2">
      
    </div>
  </div>

  
  <div class="mt-n8 position-relative">
    <div class="container">
      <div class="row justify-content-center">
        <div class="mb-4 col-lg-8 mb-lg-0">
                  
          
          <div class="card mt-4">
            <div class="card-header">
              <h5>Workers - Register for jobs</h5>
            </div>
            <div class="card-body pt-0">

              <div class="form-group mb-2">
                <label class="form-label">Date</label>
                <div class="input-group">
                  <span class="input-group-text">
                    <i class="fas fa-calendar-alt"></i>
                  </span>
                  <flat-pickr
                    v-model="date"
                    class="form-control datetimepicker"
                    placeholder="Please select date"
                    :config="config"
                  ></flat-pickr>
                </div>
              </div>

              <div class="p-3 mb-2 bg-gray-100 d-flex border-radius-lg">
                <span class="text-sm my-auto">
                  <strong>NOTE:</strong> If you register after 12:00 AM today, you will be placed on tomorrow's list.
                </span>
              </div>

              <div class="p-3 mb-3 bg-gray-100 d-flex border-radius-lg">
                <span class="text-sm my-auto">
                  <strong>NOTE:</strong> You may only register for jobs one time in the future. If you try to register for more than one day in the future, your registration will be rejected.
                </span>
              </div>
                  
              <label>Full Name</label>
              <argon-input
                id="firstname"
                type="text"
                placeholder="Your full name"
                icon-dir="left"
                icon="fas fa-user"
              />

              <label class="form-label mt-2">Phone Number</label>
              <argon-input 
                id="phone" 
                type="text" 
                placeholder="Your Phone Number"
                icon-dir="left"
                icon="fas fa-phone" />

              <label class="form-label mt-2">Position Seeking</label>
              <select
                id="position-seeking"
                class="form-control"
                name="position-seeking"
              >
                <option value="">Please select</option>
                <option value="1">Interior or Exterior</option>
                <option value="2">Exterior Team</option>
                <option value="3">Engineering Team</option>
                <option value="4">Interior Team</option>
                <option value="5">Varnish/Paint Team</option>
                <option value="6">Chef</option>
              </select>

              <label>Resume as Attachment</label>
              <form
                id="dropzone"
                action="/file-upload"
                class="form-control dropzone"
              >
                <div class="fallback">
                  <input name="file" type="file" />
                </div>
              </form>

              <!--
              <button-radio-group :options="quickPickHarborsOptions"
                button-classes="btn-secondary btn-sm mb-2 ml-2"
                v-model="quickPickHarbors">
              </button-radio-group>
              -->

            </div>
          </div>
        
        
          
          <div class="card mt-4">
            <div class="card-header d-flex">
              <h5 class="my-auto">Pick Harbor</h5>

              <div class="nav-wrapper position-relative ms-auto w-50">
                <ul class="p-1 nav nav-pills nav-fill" role="tablist">
                  <li class="nav-item">
                    <a
                      class="px-0 py-1 mb-0 nav-link active"
                      data-bs-toggle="tab"
                      href="#quickPick"
                      role="tab"
                      aria-controls="quickPick"
                      aria-selected="true"
                      >Quick Pick</a
                    >
                  </li>
                  <li class="nav-item">
                    <a
                      class="px-0 py-1 mb-0 nav-link"
                      data-bs-toggle="tab"
                      href="#orBrowse"
                      role="tab"
                      aria-controls="orBrowse"
                      aria-selected="false"
                      >Or Browse</a
                    >
                  </li>
                </ul>
              </div>

            </div>
            <div class="card-body pt-0">

              <div id="v-pills-tabContent" class="tab-content">
                <div
                  id="quickPick"
                  class="tab-pane fade show position-relative active border-radius-lg text-center"
                  role="tabpanel"
                  aria-labelledby="quickPick"
                >
                  <!-- Pick Harbor :: Quick Pick -->
                       
                    <template
                      v-for="(option, index) in quickPickHarborsOptions"
                      :key="index"
                    >
                      <input :id="`option${index}`" 
                        type="radio" 
                        class="btn-check" 
                        name="quickPickHarborsRadio"
                        :value="option.value" />
                      <label
                        class="px-3 mx-1 border-2 btn btn-xs btn-outline-info"
                        :for="`option${index}`"
                      >
                      {{ option.label }}
                      </label>
                    </template>

                  <!-- Pick Harbor :: Quick Pick :: END -->
                
                </div>

                <div
                  id="orBrowse"
                  class="tab-pane fade position-relative border-radius-lg"
                  role="tabpanel"
                  aria-labelledby="orBrowse"
                >

                  <!-- Pick Harbor :: Or Browse -->
                  <label class="form-label mt-2">Browse Port</label>
                  <select
                    id="browse-port"
                    class="form-control"
                    name="browse-port"
                    
                  >
                    <option value="">Please select</option>
                    <option 
                      v-for="(option, index) in harborOptions"
                      :key="index"
                      :value="option.value"
                    >
                      {{ option.label }}
                    </option>
                  </select>
                  <!-- Pick Harbor :: Or Browse :: END -->

                </div>
              </div>






            </div>
          </div>
        
          
          <div class="card mt-4">
            <div class="card-body pt-0">
              <div class="container">
                <div class="row justify-content-center">

                  <argon-switch id="rememberMe" name="rememberMe" wrapperClass="justify-content-center mt-4">
                    Remember Me
                  </argon-switch>

                  <argon-button
                    color="info"
                    variant="gradient"
                    full-width
                    class="w-100 mb-0 mt-4"
                    >
                    Register for Jobs
                  </argon-button>

                </div>
              </div>

            </div>
          </div>
        
        </div>
      </div>
  </div>
</div>

  <app-footer />
</template>
